import { MenuItem, Select } from '@mui/material'
import { REGION_TYPE } from '../../../../constants'

interface RegionFilterProps {
  setRegionSelect: (value: string | undefined) => void
  regionSelect: string
}

function CustomRegionFilter({ setRegionSelect, regionSelect }: RegionFilterProps) {
  return (
    <Select
      labelId="region-select-label"
      label="Region"
      value={regionSelect}
      onChange={(event) => setRegionSelect(event.target.value || undefined)}
      variant="standard"
      displayEmpty
      fullWidth
    >
      {REGION_TYPE.map((region: string) => (
        <MenuItem key={region} value={region}>
          {region}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CustomRegionFilter
