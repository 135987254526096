import { logoutAdmin } from './fetch'

export function checkAuth(res: Response) {
  if (res.status === 401) {
    res.json()
      .then((data) => {
        // eslint-disable-next-line no-alert
        alert(`Session expired: ${data?.message || 'Session expired'}`)
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Session expired')
      })
    logoutAdmin().then(() => {
      window.location.reload()
    })
  }
  if (res.status === 403) {
    res.json()
      // eslint-disable-next-line no-alert
      .then((data) => alert(`Forbidden: ${data?.message || 'Forbidden'}`))
      // eslint-disable-next-line no-alert
      .catch(() => alert('Forbidden'))
    window.location.href = '/'
  }
  return res
}
