import { useState, useEffect } from 'react'

import eventBus from '../../services/eventBus'
import { EVENT } from '../../constants'
import FeaturedDialogDetails from './FeaturedDialogDetails'
import FeaturedDialogGrid from './FeaturedDialogGrid'
import DialogDetails from '../v2/dialogs/dialogDetails/DialogDetails'

function Featured() {
  const [curId, setCurId] = useState('')
  const [showDialogDetails, setShowDialogDetails] = useState(false)
  const [refresh, setRefresh] = useState(false) // This state is toggled to rerender the dialog grid.

  useEffect(() => {
    const showDialogHelper = (e: CustomEvent) => {
      setCurId(e.detail.id)
      setShowDialogDetails(true)
    }
    eventBus.on(EVENT.SHOW_DIALOG_DETAILS, showDialogHelper)

    const goBackHelper = () => {
      setCurId('')
      setShowDialogDetails(false)
      setRefresh((r) => !r)
    }
    eventBus.on('goBackToDialogs', goBackHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_DIALOG_DETAILS, showDialogHelper)
      eventBus.remove('goBackToDialogs', goBackHelper)
    }
  }, [])

  return (
    <>
      <FeaturedDialogGrid show={!showDialogDetails} refresh={refresh} />
      { !showDialogDetails
        ? null
        : <DialogDetails quickbloxDialogIdParam={curId} />}
    </>
  )
}

export default Featured
